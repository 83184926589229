

















import {
  defineComponent,
  ref,
  // useContext,
  useFetch,
  // useRouter,
} from '@nuxtjs/composition-api';

import VatToggler from '~/components/VatToggler/VatToggler.vue';
import {
  useCart,
  useUiHelpers,
  useUiState,
} from '~/composables';
import useMenu from '~/composables/useMenu';
import { useUser } from '~/modules/customer/composables/useUser';
import type { CategoryTree } from '~/modules/GraphQL/types';

import { DesktopNavigation, MobileNavigation } from './Header/Navigation';
import TopBar from './Header/TopBar/TopBar.vue';
import TopNavigation from './Header/TopNavigation/TopNavigation.vue';
import Usps from './Header/Usps/Usps.vue';

export default defineComponent({
  components: {
    TopBar,
    TopNavigation,
    DesktopNavigation,
    Usps,
    VatToggler,
    MobileNavigation,
  },
  setup() {
    // const router = useRouter();
    // const { app } = useContext();
    const { toggleCartSidebar, toggleWishlistSidebar } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { categories: menuList, load: menuListLoad } = useMenu();
    const { cart, load: loadCart } = useCart();
    const categoryTree = ref<CategoryTree[]>([]);

    // const handleAccountClick = async () => {
    //   if (isAuthenticated.value) {
    //     await router.push(app.localeRoute({ name: 'customer-my-profile' }));
    //   } else {
    //     toggleLoginModal();
    //   }
    // };

    useFetch(async () => {
      await menuListLoad();

      if (!cart.value?.items) {
        await loadCart();
      }

      categoryTree.value = menuList.value;
    });

    return {
      categoryTree,
      getCatLink,
      // handleAccountClick,
      isAuthenticated,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
    };
  },
});
