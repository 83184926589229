


















import {
  computed, ComputedRef, defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { useAttributes } from '~/composables/useAttributes';
import { isPrinter } from '~/helpers/productHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

import { PRINTER_PRODUCT_ATTRIBUTE_LIST, PRODUCT_ATTRIBUTE_LIST } from './config';
import { ProductSpecification } from './types';

export default defineComponent({
  name: 'Specification',

  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
    isPrinter: {
      type: Boolean,
    },
  },

  setup(props) {
    const { getOptionLabel } = useAttributes();

    const isPrinterProduct: boolean = isPrinter(props.product) || props.isPrinter;
    const getValue = (key: string) => (props.product[key] ? getOptionLabel(key, `${props.product[key]}`) : null);

    // @TODO: Add fields to gql
    const specification: ComputedRef<ProductSpecification[]> = computed(() => {
      const attributeList = !isPrinterProduct ? PRODUCT_ATTRIBUTE_LIST : PRINTER_PRODUCT_ATTRIBUTE_LIST;

      return attributeList.map(({ attribute_code, attribute_label }) => ({
        title: attribute_label,
        value: getValue(`${attribute_code}`),
      })).filter((item) => item.value);
    });

    return {
      isPrinterProduct,
      specification,
    };
  },
});
