var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full"},[_c('ul',{staticClass:"mb-2.5 w-full text-left text-black text-xs sm_block lg_text-[0.9rem]",class:{
      'hidden sm_block': !_vm.isProductPage
    }},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.product.buy_together),expression:"product.buy_together"}],staticClass:"mb-1.25 text-[#c90509] text-[0.9rem] leading-[1.6]"},[_c('SvgImage',{staticClass:"align-middle text-red-darker",attrs:{"icon":"tags","width":"15","height":"15"}}),_vm._v("\n      "+_vm._s('Get 20% off Cartridge Save cartridges with this printer')+"\n    ")],1),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasBullet('printer_main_functions_bullet')),expression:"hasBullet('printer_main_functions_bullet')"}],staticClass:"desktopOnly mb-1.25 text-[0.9rem] leading-[1.6]"},[_c('SvgImage',{staticClass:"align-middle",attrs:{"icon":"check","width":"15","height":"15"}}),_vm._v("\n      "+_vm._s(_vm.capitalizeFirstLetter(_vm.product.printer_main_functions_bullet))+"\n    ")],1),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isRefurbished),expression:"isRefurbished"}],staticClass:"desktopOnly mb-1.25 text-[0.9rem] leading-[1.6]"},[_c('Tooltip',{staticClass:"inline-block cursor-help",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('SvgImage',{staticClass:"align-middle",attrs:{"icon":"check","width":"15","height":"15"}}),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.$t('Professionally refurbished printer'))+"\n          ")]),_vm._v(" "),_c('SvgImage',{staticClass:"text-gray-50 align-middle",attrs:{"icon":"circle-info","width":"15","height":"15"}})]},proxy:true}])},[_vm._v(" "),_c('p',{class:{
            'hidden': !_vm.clientSide
          }},[_vm._v("\n          "+_vm._s(_vm.$t('Product works and looks like new. Refurbished by the original manufacturer. Comes with a 1 year warranty.'))+"\n        ")])])],1),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasBullet('print_speed_colour_normal') || _vm.hasBullet('print_speed_black_normal')),expression:"hasBullet('print_speed_colour_normal') || hasBullet('print_speed_black_normal')"}],staticClass:"desktopOnly mb-1.25 text-[0.9rem] leading-[1.6]"},[_c('Tooltip',{staticClass:"inline-block cursor-help",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('SvgImage',{staticClass:"align-middle",attrs:{"icon":"check","width":"15","height":"15"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('Print Speed: Up to')))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasBullet('print_speed_colour_normal')),expression:"hasBullet('print_speed_colour_normal')"}]},[_vm._v(_vm._s(_vm.product.print_speed_colour_normal)+" in Colour")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasBullet('print_speed_colour_normal') && _vm.hasBullet('print_speed_black_normal')),expression:"hasBullet('print_speed_colour_normal') && hasBullet('print_speed_black_normal')"}]},[_vm._v("&")]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasBullet('print_speed_black_normal')),expression:"hasBullet('print_speed_black_normal')"}]},[_vm._v(_vm._s(_vm.product.print_speed_black_normal)+" in Mono")]),_vm._v(" "),_c('SvgImage',{staticClass:"text-gray-50 align-middle",attrs:{"icon":"circle-info","width":"15","height":"15"}})]},proxy:true}])},[_vm._v(" "),_c('p',{class:{
            'hidden': !_vm.clientSide
          }},[_vm._v("\n          "+_vm._s(_vm.$t('{value} Per Minute - manufacturer quoted values, measured using ISO testing standards for typical print usage, for comparison purposes only.', { value: _vm.speedPrintType }))+"\n        ")])])],1),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasBullet('printer_feature_bullet')),expression:"hasBullet('printer_feature_bullet')"}],staticClass:"desktopOnly mb-1.25 text-[0.9rem] leading-[1.6]"},[_c('SvgImage',{staticClass:"align-middle",attrs:{"icon":"check","width":"15","height":"15"}}),_vm._v("\n      "+_vm._s(_vm.product.printer_feature_bullet)+"\n    ")],1),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.interfaces),expression:"interfaces"}],staticClass:"desktopOnly mb-1.25 text-[0.9rem] leading-[1.6]"},[_c('SvgImage',{staticClass:"align-middle",attrs:{"icon":"check","width":"15","height":"15"}}),_vm._v(" "),_c('span',{domProps:{"textContent":_vm._s(_vm.interfaces)}})],1),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isProductPage && _vm.hasCartridgesIncluded),expression:"isProductPage && hasCartridgesIncluded"}],staticClass:"cartridges-included hidden mb-1.25 sm_block text-[0.9rem] leading-[1.6]"},[_c('Tooltip',{staticClass:"inline-block cursor-help",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('SvgImage',{staticClass:"align-middle",attrs:{"icon":"check","width":"15","height":"15"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.product.headline))]),_vm._v(" "),_c('SvgImage',{staticClass:"text-gray-50 align-middle",attrs:{"icon":"circle-info","width":"15","height":"15"}})]},proxy:true}])},[_vm._v(" "),_c('p',{class:{
            'hidden': !_vm.clientSide
          }},[_vm._v("\n          "+_vm._s(_vm.$t('For most cartridges this is based on 5% coverage on A4 paper.'))+"\n          "+_vm._s(_vm.$t('These numbers are based on manufacturer quoted values and are for comparison purposes only.'))+"\n        ")])])],1),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasWarrantyExtension),expression:"hasWarrantyExtension"}],staticClass:"cashback mb-1.25 text-[0.9rem] leading-[1.6]"},[_c('SvgImage',{staticClass:"align-middle text-red-darker",attrs:{"icon":"tags","width":"15","height":"15"}}),_vm._v(" "),_c('button',{staticClass:"text-black underline",on:{"click":function($event){return _vm.openModal(_vm.MODAL_NAMES.info, _vm.product.warranty_ext_lightbox_html)}}},[_vm._v("\n        "+_vm._s(_vm.$t('FREE {value} year warranty extension', { value: _vm.product.warranty_ext_years }))+"\n      ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAssociation),expression:"hasAssociation"}],staticClass:"association"},[_c('b',[_vm._v(_vm._s(_vm.$t('OR')))])])],1),_vm._v(" "),(_vm.hasCashback)?_c('CashbackBullet',{attrs:{"product":_vm.product}}):_vm._e(),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasTradeInAvailable),expression:"hasTradeInAvailable"}],staticClass:"cashback mb-1.25 text-[0.9rem] leading-[1.6]"},[_c('SvgImage',{staticClass:"align-middle text-red-darker",attrs:{"icon":"tags","width":"15","height":"15"}}),_vm._v(" "),_c('span',{staticClass:"text-black underline",on:{"click":function($event){return _vm.openModal(_vm.MODAL_NAMES.info, _vm.product.trade_in_lightbox_html)}}},[_vm._v("\n        "+_vm._s(_vm.$t('Trade in your old printer and save £{value}', { value: _vm.product.trade_in_value }))+"\n      ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }