import {
  computed,
  onMounted, reactive, useContext,
} from '@nuxtjs/composition-api';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import merge from 'lodash-es/merge';

import { useConfig } from '~/composables';
import {
  getBeforeCutOffTime, getCutOffTime, getFormattedDate, isHolidays,
} from '~/composables/useShipmentDelivery/helpers';

import {
  ShipmentDeliverySettings, UseShipmentDeliveryInterface,
} from './useShipmentDelivery';

export function useShipmentDelivery(): UseShipmentDeliveryInterface {
  const { shipmentConfig } = useConfig();
  const { app: { i18n } } = useContext();

  onMounted(() => {
    dayjs.extend(advancedFormat);
  });

  const settings = reactive<ShipmentDeliverySettings>({
    headerMessage: 'FREE next-day delivery on orders over £30.',
    xMasMessage: 'Christmas delivery info',

    dispatchDate: null,
    uspEstimateText: null,
    footerText: '',

    deliveryUrl: '/i/free-delivery.html',
    footerDateFormat: 'dddd Do MMMM',
    uspDateFormat: 'dddd',
    dispatchText: {
      christmas: i18n.t('Christmas delivery information'),
      today: '',
      afterToday: i18n.t('Order now for dispatch on '),
      afterCutOff: i18n.t('Order now for dispatch tomorrow'),
    },
    nextDeliveryOffset: 0,
  });

  const holidays = computed(() => merge([], shipmentConfig.holidays));

  const nextWorkingDay = (today) => {
    let currentOffset = 0;
    let totalOffset = 0;
    let day = today;

    while (currentOffset !== settings.nextDeliveryOffset) {
      day = day.add(1, 'day');

      if (!isHolidays(day, holidays.value)) {
        currentOffset++;
      }

      totalOffset++;
    }

    settings.nextDeliveryOffset = totalOffset;

    return day;
  };

  const showChristmasDelivery = computed(() => shipmentConfig.show_christmas_delivery);
  const isChristmasEnabled = computed(() => shipmentConfig.christmas_enabled);

  const setUspText = () => {
    settings.uspEstimateText = settings.dispatchText.today;

    if (showChristmasDelivery.value) {
      settings.uspEstimateText = settings.dispatchText.christmas;
    } else if (settings.nextDeliveryOffset > 0) {
      settings.uspEstimateText = `${settings.dispatchText.afterToday}${getFormattedDate(settings.dispatchDate, settings.uspDateFormat)}`;
    }
  };

  const setFooterText = () => {
    let text = settings.dispatchText.today;

    if (!shipmentConfig.delivery_estimate || !settings.dispatchDate || showChristmasDelivery.value) {
      return;
    }

    if (settings.nextDeliveryOffset > 0) {
      text = `${settings.dispatchText.afterToday}${getFormattedDate(settings.dispatchDate, settings.footerDateFormat)}`;
    }

    settings.footerText = text;
  };

  const productDeliveryText = computed(() => {
    const now = dayjs();
    const tomorrow = now.add(1, 'day');

    if (isHolidays(now, holidays.value)) {
      return `${settings.dispatchText.afterToday}${getFormattedDate(settings.dispatchDate, settings.uspDateFormat)}`;
    } if (!getBeforeCutOffTime(now, shipmentConfig.cut_off_time) && !isHolidays(tomorrow, holidays.value)) {
      return settings.dispatchText.afterCutOff;
    }

    return settings.dispatchText.today;
  });

  settings.dispatchText.today = `Order before ${getCutOffTime(shipmentConfig.cut_off_time)} for dispatch today`;

  if (showChristmasDelivery.value) {
    settings.dispatchDate = shipmentConfig.delivery_date_after_christmas || '';

    setUspText();

    return {};
  }

  const now = dayjs();

  settings.nextDeliveryOffset = !getBeforeCutOffTime(now, shipmentConfig.cut_off_time) || isHolidays(now, holidays.value) ? 1 : 0;

  const nextDate = nextWorkingDay(now);

  if (nextDate) {
    settings.dispatchDate = nextDate;
    setFooterText();
    setUspText();
  }

  return {
    productDeliveryText,
    headerMessage: settings.headerMessage,
    footerText: settings.footerText,
    xMasMessage: settings.xMasMessage,
    uspEstimateText: settings.uspEstimateText,
    showChristmasDelivery,
    isChristmasEnabled,
  };
}

export * from './useShipmentDelivery';
export default useShipmentDelivery;
