











import {
  computed,
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { useTax } from '~/composables';
import { AlternativeProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'CompatibleSaves',

  props: {
    alternativeProduct: {
      type: Object as PropType<AlternativeProductInterface>,
      required: true,
    },
  },

  setup(props) {
    const { taxState } = useTax();

    const compatibleSavesPrice = computed(() => {
      if (taxState.value && props.alternativeProduct.price_difference_incl_tax) {
        return props.alternativeProduct.price_difference_incl_tax.toFixed(2);
      }

      if (props.alternativeProduct.price_difference) {
        return props.alternativeProduct.price_difference.toFixed(2);
      }

      return 0;
    });

    return {
      compatibleSavesPrice,
    };
  },
});

