










































































import {
  defineComponent,
} from '@nuxtjs/composition-api';

import SwitchAndSaveHeader from '../Header.vue';

export default defineComponent({
  name: 'SwitchAndSaveContent',
  components: {
    SwitchAndSaveHeader,
  },
  props: {
    isProductPage: {
      require: false,
      type: Boolean,
      default: false,
    },
  },
});
