


























import { defineComponent, useRoute } from '@nuxtjs/composition-api';
import ClientOnly from 'vue-client-only';
import LazyHydrate from 'vue-lazy-hydration';

import IconSprite from '~/components/General/IconSprite.vue';
import AppHeader from '~/components/layout/AppHeader.vue';
import Modals from '~/components/layout/Modals/Modals.vue';
import Overlay from '~/components/layout/Overlay/Overlay.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import ZendeskButton from '~/components/ZendeskButton.vue';
import { useUiState } from '~/composables';
import { useAttributes } from '~/composables/useAttributes';
import { HOME_BLOCK_IDS, APP_ROUTER_PATHS } from '~/constants';
import isServer from '~/helpers/isServer';
import { useCmsBlocksStore } from '~/stores/cmsBlocks';

let CMS_BLOCK_IDS = ['delivery_replacement_header', 'delivery_replacement_footer', 'footer_company_payment', 'delivery_price_footer'];

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    IconSprite,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/layout/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/LoginModal/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    Overlay,
    Modals,
    ZendeskButton,
    ClientOnly,
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal, isOverlay,
    } = useUiState();
    const { load } = useCmsBlocksStore();
    const { load: loadAttributes } = useAttributes();
    // check if it is home and if yes merge blocks
    if (route.value.path === APP_ROUTER_PATHS.home) {
      CMS_BLOCK_IDS = [...CMS_BLOCK_IDS, ...HOME_BLOCK_IDS];
    }

    load(CMS_BLOCK_IDS);
    loadAttributes();

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      route,
      isOverlay,
      isServer,
      toggleLoginModal,
    };
  },
  head: {
    link: [
      // {
      //   rel: 'preload',
      //   href: '/_nuxt/fonts.css',
      //   as: 'style',
      //   onload: 'this.onload=null;this.rel=\'stylesheet\'',
      // },
    ],
    noscript: [
      { innerHTML: '<link rel="stylesheet" href="/_nuxt/fonts.css">' },
    ],
  },
});
