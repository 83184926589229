























































































































































































































































































import {
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import Tooltip from '~/components/common/Tooltip.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useProductBullets, useUiState } from '~/composables';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'NewVisibilityBullets',

  components: {
    SvgImage,
    Tooltip,
  },

  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
    compatibilityBrand: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const { toggleAdditionalModal } = useUiState();

    const {
      MODAL_NAMES,
      skuComponentTitle,
      hasReturnProgramDetails,
      isProductGenuine,
      isPromotion,
      tooltipContent,
      clientSide,
      openBrandHoverModal,
    } = useProductBullets({ product: props.product });

    const openModal = (identifier: string) => {
      toggleAdditionalModal({
        identifier,
        props: {
          brand: props.compatibilityBrand,
          product: props.product,
        },
      });
    };

    return {
      MODAL_NAMES,
      skuComponentTitle,
      hasReturnProgramDetails,
      isProductGenuine,
      isPromotion,
      tooltipContent,
      clientSide,
      openModal,
      openBrandHoverModal,
    };
  },
});

