








































































































































































































import {
  computed,
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { CashbackBullet } from '~/components/Cashback';
import { MODAL_NAMES } from '~/components/common/SimpleModal';
import Tooltip from '~/components/common/Tooltip.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useCashback, useUiState } from '~/composables';
import { useAttributes } from '~/composables/useAttributes';
import { PRINTER_REFURBISHED, REFURBISHED_IN_SKU } from '~/constants';
import isServer from '~/helpers/isServer';
import { capitalizeFirstLetter } from '~/helpers/textHelpers';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'PrinterBullets',

  components: {
    CashbackBullet,
    SvgImage,
    Tooltip,
  },

  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
    isProductPage: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { toggleModal } = useUiState();
    const { getOptionLabel } = useAttributes();
    const { hasCashback } = useCashback(props.product);

    const hasBullet = (attribute: string) => props.product[attribute]?.length > 0;
    const isWithinDate = (startDateAttr: string, endDateAttr: string) => {
      if (hasBullet(endDateAttr)) {
        const endDateValue: string = props.product[endDateAttr];
        const endDate = new Date(endDateValue);
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        if (hasBullet(startDateAttr)) {
          const startDateValue: string = props.product[startDateAttr];
          const startDate = new Date(startDateValue);

          if (startDate < today && endDate > yesterday) {
            return true;
          }
        } else if (endDate > yesterday) {
          return true;
        }
      }

      return false;
    };

    // eslint-disable-next-line max-len
    const isRefurbished = computed(() => (+props.product.printer_condition === PRINTER_REFURBISHED) || props.product.sku.includes(REFURBISHED_IN_SKU));
    const speedPrintType = computed(() => {
      const exp = 'ipm';

      if (hasBullet('print_speed_colour_normal') && props.product.print_speed_colour_normal.includes(exp)) {
        return 'Images';
      }

      if (hasBullet('print_speed_black_normal') && props.product.print_speed_colour_normal && props.product.print_speed_black_normal.includes(exp)) {
        return 'Images';
      }

      return 'Pages';
    });
    const interfaces = computed(() => {
      const interfaceText = props.product.standard_interfaces?.split(',').map((item) => getOptionLabel('standard_interfaces', item)).filter(Boolean);

      return [...new Set(interfaceText)].join(', ');
    });
    const hasCartridgesIncluded = computed(() => {
      const ext = 'Cartridges included:';

      return hasBullet('headline') && props.product.headline && props.product.headline.includes(ext);
    });
    const hasWarrantyExtension = computed(() => isWithinDate('warranty_ext_start_date', 'warranty_ext_end_date'));
    const hasTradeInAvailable = computed(() => isWithinDate('trade_in_start_date', 'trade_in_end_date'));
    const hasAssociation = computed(() => hasWarrantyExtension.value && hasCashback.value && props.product.cashback_warranty_association);

    const openModal = (identifier: string, content: string) => {
      toggleModal({
        identifier,
        props: {
          content,
        },
      });
    };

    return {
      MODAL_NAMES,
      hasCashback,
      isRefurbished,
      speedPrintType,
      interfaces,
      hasCartridgesIncluded,
      hasWarrantyExtension,
      hasTradeInAvailable,
      hasAssociation,
      clientSide: computed(() => !isServer),
      openModal,
      capitalizeFirstLetter,
      hasBullet,
    };
  },
});
