





































































































































































import {
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { CashbackBullet } from '~/components/Cashback';
import Tooltip from '~/components/common/Tooltip.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useProductBullets } from '~/composables';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'ListingProductBullets',

  components: {
    CashbackBullet,
    SvgImage,
    Tooltip,
  },

  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      default: null,
    },
    compatibilityBrand: {
      type: String,
      default: '',
    },
    mobileView: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    return useProductBullets({ product: props.product, compatibilityBrand: props.compatibilityBrand });
  },
});

