



















import {
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import { useCashback } from '~/composables';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'CashbackImage',
  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      required: true,
    },
  },

  setup(props) {
    const { isUpTo, openModal } = useCashback(props.product);

    return {
      isUpTo,
      openModal,
    };
  },
});

