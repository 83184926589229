








import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'SwitchAndSaveLabel',
  props: {
    forceShowLabel: {
      type: Boolean,
      default: false,
    },
    isGenuine: {
      type: Boolean,
      default: false,
    },
  },
});
