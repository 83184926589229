























































import {
  computed, defineComponent, onMounted, onUnmounted, ref, toRef, useCssModule, watch,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'SimpleModal',
  components: { SvgImage },
  props: {
    name: {
      required: true,
      type: String,
    },
    delay: {
      required: false,
      type: Number,
      default: 300,
    },
    width: {
      type: Number,
      default: 0,
    },
    transitionName: {
      type: String,
      default: 'modal-animation',
    },
    closeBtn: {
      required: false,
      type: Boolean,
      default: true,
    },
    isPercentWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    propsStyles: {
      type: Object,
      default: null,
    },
    isTopAlign: {
      type: Boolean,
      default: false,
    },
    isWideContainer: {
      type: Boolean,
      default: false,
    },
    isNarrowContainer: {
      type: Boolean,
      default: false,
    },
    isSimpleAnimation: {
      type: Boolean,
      default: false,
    },
    isCloseTriggered: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isCloseTriggered = toRef(props, 'isCloseTriggered');
    const {
      modal, modalAdditional, toggleModal, setOverlay, toggleAdditionalModal,
    } = useUiState();
    const defaultStyles = useCssModule();
    const isAnimation = ref(false);

    const computedStyles = computed(() => ({ ...defaultStyles, ...props.propsStyles }));
    const isAdditionalVisible = computed(() => modalAdditional.value.identifier === props.name);
    const isVisible = computed(() => modal.value.identifier === props.name || isAdditionalVisible.value);
    const contentStyles = computed(() => {
      const widthUnit = props.isPercentWidth ? '%' : 'px';

      return props.width ? `width: ${props.width}${widthUnit}` : '';
    });

    const onToggle = (modalState: boolean, delay?: number) => {
      if (isAdditionalVisible.value) {
        toggleAdditionalModal();
      } else {
        toggleModal();
      }

      if (modalState) {
        setOverlay(modalState);
        return;
      }

      setTimeout(() => {
        setOverlay(modalState);
        isAnimation.value = false;
      }, delay || props.delay);
    };

    const onClose = () => {
      isAnimation.value = true;
      onToggle(false, 500);
    };

    const escapeKeydownHandler = (e) => {
      // for old browser support as a fallback
      if ((e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) && isVisible.value) {
        onClose();
      }
    };

    onMounted(() => {
      document.addEventListener('keydown', escapeKeydownHandler, { passive: true });
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', escapeKeydownHandler);
    });

    watch(isCloseTriggered, () => {
      if (isCloseTriggered.value) {
        onClose();
      }
    });

    return {
      isVisible,
      onClose,
      contentStyles,
      computedStyles,
      isAnimation,
      isAdditionalVisible,
    };
  },
});
