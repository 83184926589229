export const PRINTERS_ATTRIBUTE_SET_ID = 21;
export const HP_BRAND_ID = 1991;
export const PRINTER_REFURBISHED = 2;
export const CARTRIDGE_REFURBISHED = 2421;
export const REFURBISHED_IN_SKU = '-RFB';
export const PAPER_CATEGORY_ID = 214_818;
export const EXCLUSIVE_LAYOUT_URLS = ['label-tape', 'find-paper-and-labels'];
export const SWITCH_AND_SAVE_PATHS = [207_140, 207_009];

export const REVIEW_URL = '/review/product/form/id/';

export const ADD_TO_CART_QTY = '1';

export const IN_STOCK = 'IN_STOCK';

export const AVAILABLE_PRODUCT_STATUS = [0, 1];
export const AVAILABLE_PRODUCT_VISIBILITY = [2, 3, 4];
export const MAINTENANCE_ATTRIBUTE_IDS = [1831, 1853];

export const CMS_PAGE_SLAVERY_IDENTIFIER = 'slavery-any-year';

export const DEFAULT_CATEGORY_UID = 'MjA3MDA4';
export const PRINTERS_CATEGORY_UID = 'MjE3MzEx';
export const PRINTERS_PAGE_SIZE = 15;
export const PRINTERS_PAGINATION_URL = '/printers.html?page=';

export const RANGE_FILTERS = ['print_speed_filters', 'price'];

export const FREE_DELIVERY_AMOUNT = 30;

export const PAGE_SUFFIX = '.html';

export const HOME_BLOCK_IDS = [
  'home_about_cartridgesave',
  'home_usp_2',
  'home_popular_inkjet_printer_list',
  'home_popular_laser_printer_list',
  'home_aw_customer_service',
];

export const PLP_GROUPS_ORDER = [
  'Full sets of toner cartridges',
  'Full sets of toner cartridges plus extra black',
  'Colour toner cartridge packs',
  'Sets of toner cartridges',
  'Black filaments',
  'Black toner cartridges',
  'Blue filaments',
  'Cyan toner cartridges',
  'Brown filaments',
  'Magenta toner cartridges',
  'Cyan filaments',
  'Yellow toner cartridges',
  'Sets of ink ribbon',
  'Green filaments',
  'Black drum and toner units',
  'Grey filaments',
  'Black ink ribbons',
  'Magenta filaments',
  'Colour ink ribbons',
  'Orange filaments',
  'Blue ink ribbons',
  'Purple filaments',
  'Red ink ribbons',
  'Red filaments',
  'Red and black ink ribbons',
  'Silver filaments',
  'Lift-off correction tape',
  'Transparent filaments',
  'Sets of solid wax inks',
  'White filaments',
  'Black solid wax inks',
  'Yellow filaments',
  'Cyan solid wax inks',
  'Packs of cyan and black solid wax inks',
  'Magenta solid wax inks',
  'Packs of magenta and black solid wax inks',
  'Yellow solid wax inks',
  'Packs of yellow and black solid wax inks',
  'Sets of ink cartridges',
  'Black ink cartridges',
  'Matte black ink cartridges',
  'Photo black ink cartridges',
  'Colour ink cartridges',
  'Cyan ink cartridges',
  'Light cyan ink cartridges',
  'Photo cyan ink cartridges',
  'Magenta ink cartridges',
  'Light magenta ink cartridges',
  'Photo magenta ink cartridges',
  'Yellow ink cartridges',
  'Red ink cartridges',
  'Green ink cartridges',
  'Blue ink cartridges',
  'Orange ink cartridges',
  'Photo colour ink cartridges',
  'Grey ink cartridges',
  'Light grey ink cartridges',
  'Photo grey ink cartridges',
  'Light black ink cartridges',
  'Dark grey ink cartridges',
  'Light light black ink cartridges',
  'Violet ink cartridges',
  'Ink cartridges',
  'Other cartridges',
  'Colour photo packs',
  'Black and white photo packs',
  'Printheads',
  'Do it yourself cartridge refill kits',
  'Sets of maintenance items',
  'Maintenance items',
  'Cables',
  'Paper and labels',
  'Address labels',
  'Black on blue tape cartridges',
  'Black on clear tape cartridges',
  'Black on green tape cartridges',
  'Black on orange tape cartridges',
  'Black on red tape cartridges',
  'Black on silver matt tape cartridges',
  'Black on white tape cartridges',
  'Black on yellow tape cartridges',
  'Black solid wax inks',
  'Black tape cartridges',
  'Blue on clear tape cartridges',
  'Blue on white tape cartridges',
  'Gold on black tape cartridges',
  'Name Badge Labels',
  'Red on clear tape cartridges',
  'Red on white tape cartridges',
  'White on black tape cartridges',
  'White on blue tape cartridges',
  'White on clear tape cartridges',
  'White on red tape cartridges',
  'Black on dark green tape cartridges',
  'Black on metallic sliver tape cartridges',
  'Franking labels',
  'Paper and labels',
  'Red and black on white tape cartridges',
  'Sets of tape cartridges',
];
