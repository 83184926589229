export const CONFIG = {
  endpoint: 'https://beta.csave.net/api/ext/back-in-stock/notification',
  // endpoint: '/api/ext/back-in-stock/notification',
  options: {
    mode: 'cors',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
  },
};
