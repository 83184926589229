import gql from 'graphql-tag';

const cmsBlocksQuery = gql`
  query cmsBlock($identifiers: [String]) {
    cmsBlocks(identifiers: $identifiers) {
      items {
        content
        identifier
        title
      }
    }
  }
`;

const cmsPageQuery = gql`
  query cmsPage($identifier: String) {
    cmsPage(identifier:$identifier) {
      identifier
      content
      title
      meta_title
      meta_description
      meta_keywords
      content_heading
    }
  }
`;

const routeQuery = gql`
  query route($url: String!) {
    route(url: $url) {
      type
       ... on ProductInterface {
        sku
      }
      ... on CategoryTree {
        uid
      }
      ... on CmsPage {
        identifier
      }
    }
  }
`;

export { cmsBlocksQuery, cmsPageQuery, routeQuery };
