




















import { computed, defineComponent } from '@nuxtjs/composition-api';

const MAX_STARS_AMOUNT = 5;

export default defineComponent({
  name: 'RatingStars',
  props: {
    rating: {
      type: [String, Number],
      required: true,
      default: 0,
    },
  },
  setup(props) {
    const width = computed(() => `${(+props.rating * 100) / MAX_STARS_AMOUNT}%`);

    return {
      width,
    };
  },
});
