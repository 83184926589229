import {
  fragmentCashback, fragmentPrice, fragmentProduct, fragmentStock,
} from '~/customQueries/productDetails';

export default `
  query categoryMeta($filters: CategoryFilterInput) {
    categories(filters: $filters) {
      items {
        breadcrumbs {
          category_id
          category_name
          category_url_path
        }
        id
        uid
        level
        description
        printer_message
        canonical_url
        product_count
        name
        children_count
        title
        url_path
        path
        image
        additional_content
        meta_description
        meta_title
        printer_as_product {
          ...Price
          stock {
            is_in_stock
            qty
          }
          url_key
        }
        faq {
          category_data {
            product_url
            short_name
            yield_text
          }
          category_prefix
        }
        review_stars
        verdict_text
        review_text
        products {
          items {
            group
            price_tiers {
              quantity
              final_price {
                value
              }
              final_price_incl_tax {
                value
              }
            }
            yields {
              color
              swatches
              yield_text
              yield
            }
            return_program_hover_over
            return_program_hover_show
            cartridge_condition
            sku_pack_component
            due_in_date
            __typename
            ...BaseProduct
            ...Cashback
            ...Price
            ...Stock
          }
        }
        bestsellers {
          name
          url
          product_id
          price_incl_vat
          price_excl_vat
          thumbnail
          sku
        }
        children {
          title
          url_key
          printer_family
          product_count
          canonical_url
        }
      }
    }
  }
  ${fragmentProduct}
  ${fragmentPrice}
  ${fragmentCashback}
  ${fragmentStock}
`;
