import { Middleware } from '@nuxt/types';

import { PRINTERS_CATEGORY_UID } from '~/constants';
import getObjectByString from '~/helpers/getObjectByString';
import { Logger } from '~/helpers/logger';
import { getConfigByPageType, getProductsData, PageEntityTypeEnum } from '~/helpers/middleware/pagePreloadHelpers';
import { usePageStore } from '~/stores/page';

async function getEntityResults(context, pageStore, config, loggerMsg) {
  const {
    queryName, getParams, stateKey, resultFn,
  } = config;
  const { data, errors } = await context.app.$vsf.$magento.api[queryName](...getParams(pageStore.routeData, context.route));
  const results = getObjectByString(data, `${resultFn}`);
  Logger.debug(`middleware/page-preload/${loggerMsg}`, { data, errors });

  return { results, stateKey };
}

/** Middleware for loading data before entering the next route */
const pagePreloadResolverMiddleware : Middleware = async (context) => {
  if (!process.env.IS_PAGE_PRELOAD_MIDDLEWARE) {
    return;
  }

  const pageStore = usePageStore();
  const { routeData } = pageStore;
  const currentConfig = getConfigByPageType(`${routeData?.type}`);
  const categoryUid = routeData?.uid;

  // Skip loading if uid not changed (printers dynamic categories)
  if (currentConfig && !(categoryUid && pageStore?.[currentConfig.stateKey]?.uid === categoryUid)) {
    const { results, stateKey } = await getEntityResults(context, pageStore, currentConfig, 'result');

    if (!results) context.error({ statusCode: 404 });

    pageStore.$patch((state) => {
      state[stateKey] = results;
    });
  }

  // Load additional data (products) for search and printer pages
  const additionalType = routeData?.uid === PRINTERS_CATEGORY_UID || context.route.name === 'search' ? PageEntityTypeEnum.CategoryProducts : `${routeData?.additionalType}`;
  const additionalConfig = additionalType ? getConfigByPageType(additionalType) : null;

  if (additionalConfig) {
    const { results, stateKey } = await getEntityResults(context, pageStore, additionalConfig, 'additional-result');

    pageStore.$patch((state) => {
      state[stateKey] = results ? getProductsData(results, context.route, pageStore.routeData) : null;
    });
  }
};

export default pagePreloadResolverMiddleware;
