































import {
  defineComponent, PropType, ref,
} from '@nuxtjs/composition-api';

import { useUiHelpers, useUiState } from '~/composables';
import useMenu from '~/composables/useMenu';
import { CategoryTree } from '~/modules/GraphQL/types';
import type { ComponentTemplateRef } from '~/types/componentTemplateRef';

const EXCLUDED_SUB_MENUS = new Set(['Label Tape', 'Paper', 'Printers']);

export default defineComponent({
  name: 'DesktopNavigation',
  components: {
    SubMenu: () => import('../components/SubMenu/SubMenu.vue'),
  },
  props: {
    categoryTree: {
      type: Array as PropType<CategoryTree[]>,
      default: () => [],
    },
  },
  setup() {
    const { getCatLink } = useUiHelpers();
    const { setOverlay } = useUiState();
    const { currentCategory, setCurrentCategory, isSubmenu } = useMenu();

    const lvl0CatRefs = ref<ComponentTemplateRef[]>();

    const handleSetCurrentCategory = (category: CategoryTree | null) => {
      setCurrentCategory(category);
      setOverlay(false);
    };

    const onMouseEnter = (category: CategoryTree) => {
      currentCategory.value = category;

      if (!EXCLUDED_SUB_MENUS.has(category?.name)) {
        setOverlay(true);
      }
    };

    return {
      getCatLink,
      handleSetCurrentCategory,
      currentCategory,
      isSubmenu,
      lvl0CatRefs,
      onMouseEnter,
    };
  },
});
