






























































import {
  computed,
  defineAsyncComponent,
  defineComponent,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES } from '~/components/common/SimpleModal';
import SvgImage from '~/components/General/SvgImage.vue';
import { usePageHelpers, useUiState } from '~/composables';
import isServer from '~/helpers/isServer';

const CONFIG = {
  default: {
    width: 400,
    height: 400,
  },
  mobile: {
    width: 380,
    height: 400,
  },
  thumbnail: {
    width: 90,
    height: 90,
  },
};

export default defineComponent({
  name: 'SimpleImage',
  components: {
    SvgImage,
    VZoomer: defineAsyncComponent(() => import('vue-zoomer/src/vue-zoomer.vue')),
  },
  props: {
    src: {
      type: String,
      default: '/product-page/no-product-image.png',
    },
    isVideoThumb: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: '',
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    isZoom: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 500,
    },
    height: {
      type: [Number, String],
      default: 500,
    },
    isLightboxImage: {
      type: Boolean,
      default: false,
    },
    isThumbnail: {
      type: Boolean,
      default: false,
    },
    isLazyLoad: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { toggleModal } = useUiState();
    const { isPrinterPage } = usePageHelpers();

    const { default: { width: defaultWidth, height: defaultHeight }, thumbnail: { width: thumbWidth, height: thumbHeight } } = CONFIG;

    const thumbnailsSrc = computed(() => {
      const image = props.src;

      return image.replace(`/${defaultWidth}/`, `/${thumbWidth}/`).replace(`/${defaultHeight}/`, `/${thumbHeight}/`);
    });

    const responsiveSrc = computed(() => {
      const image = props.src;

      if (props.isThumbnail) {
        return thumbnailsSrc.value;
      }

      if (!isServer && window.innerWidth < 768) {
        const { width: mobileDefaultWidth, height: mobileDefaultHeight } = CONFIG.mobile;

        const mobileWidth = window.innerWidth < 360 ? window.innerWidth * 0.7 : mobileDefaultWidth;
        const mobileHeight = mobileWidth || mobileDefaultHeight;

        return image.replace(`/${defaultWidth}/`, `/${mobileWidth}/`).replace(`/${defaultHeight}/`, `/${mobileHeight}/`);
      }

      return image;
    });

    const openImageLightbox = () => {
      if (!props.isLightboxImage) return;

      toggleModal({
        identifier: MODAL_NAMES.lightboxImage,
        props: {
          image: {
            src: thumbnailsSrc.value,
            alt: props.alt,
          },
        },
      });
    };

    return {
      responsiveSrc,
      isPrinterPage,
      isServer,
      openImageLightbox,
    };
  },
});
