


















import {
  defineComponent, PropType,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { useCashback, useMagentoConfiguration } from '~/composables';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'CashbackBullet',
  components: { SvgImage },
  props: {
    product: {
      type: [Object, null] as PropType<ProductInterface | null>,
      required: true,
    },
  },

  setup(props) {
    const {
      getPriceWithCurrency,
    } = useMagentoConfiguration();
    const { isUpTo, openModal } = useCashback(props.product);

    return {
      isUpTo,
      getPriceWithCurrency,
      openModal,
    };
  },
});

