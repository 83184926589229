




























































































































































import {
  defineComponent,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'SwitchAndSellInfo',
  components: {
    SvgImage,
  },

  props: {
    info: {
      type: Object,
      required: true,
    },
  },
});
