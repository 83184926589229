import {
  computed, ref,
} from '@nuxtjs/composition-api';

import { CategoryTree } from '~/modules/GraphQL/types';
import { useMenuStore } from '~/stores/menu';

import type {
  UseMenuInterface,
} from './useMenu';

const EXCLUDED_SUB_MENUS = new Set(['Label Tape', 'Paper', 'Printers']);

export function useMenu(): UseMenuInterface {
  const menuStore = useMenuStore();
  const categories = computed(() => menuStore.categories);
  const load = () => menuStore.load();
  const getCategoryById = (parentId: number, categoryId: number) => menuStore.getCategoryById(parentId, categoryId);

  const currentCategory = ref<CategoryTree | null>(null);
  const isSubmenu = (category: CategoryTree) => Boolean(category?.children?.length) && !EXCLUDED_SUB_MENUS.has(category?.name);

  const setCurrentCategory = (category: CategoryTree | null) => {
    currentCategory.value = category;
  };

  const topCategories = computed(() => categories.value?.filter((category) => {
    const { level, product_count: productCount, children_count: childrenCount } = category;

    return level === 2 && (productCount > 0 || +childrenCount > 0);
  }));

  return {
    categories,
    currentCategory,
    topCategories,
    load,
    isSubmenu,
    setCurrentCategory,
    getCategoryById,
  };
}

export * from './useMenu';

export default useMenu;
