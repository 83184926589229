export const IMAGE_CONFIG = {
  baseUrl: '/img/',
  useSpecificImagePaths: true,
  paths: {
    product: '/catalog/product',
    category: '/catalog/category',
    brandLogo: '/wysiwyg/thumbnail/brand',
  },
  productPlaceholder: '/assets/placeholder.png',
};
